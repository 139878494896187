@use "sass:meta";
@import "~tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@include meta.load-css("../../node_modules/country-flag-icons/3x2/flags.css");

@layer base {
  :root {
    --color-body-background: #00B8FF;

    --color-primary: var(--color-body-background);
    --color-secondary: #002533;

    --color-success: #16A34A;
    --color-danger: #FF5252;

    --button-color-primary: theme(colors.secondary);
    --button-color-secondary: theme(colors.secondary);
    --button-border-radius: theme(borderRadius.md);

    --font-family-heading: "Poppins", "sans-serif";
    --font-family-regular: "Noto Sans", "sans-serif";

    [class*=' flag:'],
    [class^='flag:'] {
      font-size: 24px;
      border-radius: 3px;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }

    .facelift {
      @include meta.load-css("./facelift-flags.css");

      --color-body-background: #FFFFFF;

      --color-primary: #00B8FF;
      --color-secondary: #06025C;
      --color-tertiary: #5555B6;

      --button-color-primary: theme(colors.primary);
      --button-color-secondary: theme(colors.secondary);
      --button-border-radius: 999px;

      --font-family-heading: "Poppins", "sans-serif";
      --font-family-regular: "Noto Sans", "sans-serif";

      [class*=' flag:'],
      [class^='flag:'] {
        font-size: 24px;
        border-radius: 99px;
      }
    }
  }
}

@layer components {
  body {
    font-family: var(--font-family-regular);
  }
  .no-spinner {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  .no-spinner::-webkit-outer-spin-button,
  .no-spinner::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  .text-header {
    @apply text-[32px] leading-[42px] text-white mt-5 mb-2 font-semibold;
  }
  .text-subheader {
    @apply text-[18px] leading-[22px] text-white mb-6;
  }
  .text-body {
    @apply text-[16px] leading-[20px] text-white;
  }

  .info-wrapper {
    @apply my-12;
  }

  .btn {
    @apply py-[12px] rounded-[--button-border-radius] shadow-regular w-full text-center text-white font-regular disabled:opacity-disabled;

    &-primary {
      background-color: var(--button-color-primary);
    }

    &-secondary {
      @apply bg-transparent shadow-none;
    }
  }

  .facelift {
    font-family: var(--font-family-regular);
    .text-header {
      @apply text-2xl leading-7 text-secondary mt-5 mb-2 font-semibold font-heading;
    }
    .text-subheader {
      @apply text-base leading-5 text-secondary mb-6 font-regular;
    }
    .text-body {
      @apply text-base leading-5 text-secondary font-regular;
    }
    .info-wrapper {
      @apply my-3;
    }
    .btn {
      @apply disabled:bg-[#CECEE8] disabled:opacity-100;

      &-secondary {
        @apply text-primary bg-transparent border border-primary disabled:border-none disabled:text-white;
      }
    }
  }
}

// Default Flag
.flag\:DFLT {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 85.333 512 341.333'%3e%3cpath fill='%236DA544' d='M0 85.337h512v341.326H0z'/%3e%3cpath fill='%23FFDA44' d='M0 85.337h512v113.775H0z'/%3e%3cpath fill='%23D80027' d='M0 312.888h512v113.775H0z'/%3e%3c/svg%3e");
  filter: grayscale(100)
}

.with-marker {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: .625rem;
    height: .5rem;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2212%22%20height%3D%2210%22%20viewBox%3D%220%200%2012%2010%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1%205.98824L4.42857%209L11%201%22%20stroke%3D%22%2300B8FF%22%20stroke-width%3D%221.5%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    transform: scale(1.5) translateY(calc(-50% + 0.15rem));
    left: -1.25rem;
    top: calc(50% - 0.1rem);
  }
}
